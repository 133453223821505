import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { Link } from "gatsby"

import ArrowRight from "images/arrow-right.inline.svg"

const ServicesFourthSection = () => {
  const { t } = useTranslation()

  return (
    <section className="container section-padding">
      <h2 className="bold-40 text-center mb-100 section-subtitle">
        {t(
          "Store your patients' vascular data in the UVASC Cloud health data library"
        )}
      </h2>
      <div className="row lh-2 mb-100">
        <div className="col">
          <h3 className="bold-30 mb-2">{t("Data storage")}</h3>
          <p className="bold-24 mb-2">
            {t(
              "You benefit from health data storage on secure, HDS-approved servers"
            )}
            .
          </p>
          <p className="light-24 mb-2">
            {t(
              "The retention period for data in the cloud is 5 years under a liberal subscription"
            )}
            .
          </p>
          <p className="light-24">
            <Trans>
              Eventually <strong className="bold-24">UVASC Cloud</strong>, via
              its API, will allow you to store your health data in the cloud of
              different health devices respecting the evolution of{" "}
              <strong className="bold-24">the DICOM standard</strong> towards{" "}
              <strong className="bold-24">the HL7/FHIR standard</strong>
            </Trans>
            .
          </p>
        </div>
        <div className="col">
          <h3 className="bold-30 mb-2">{t("Your files in security")}</h3>
          <p className="light-24 mb-2">
            {t(
              "We use recommended cyber protection methods to protect your health data"
            )}
            .
          </p>
          <p className="light-24 mb-2">
            <span className="bold-24">UVASC Cloud</span>{" "}
            {t(
              "is a platform that allows you to store health data from your medical software solution in a way that respects"
            )}{" "}
            <span className="bold-24">{t("HDS certification and RGPD")}</span>.
          </p>
          <p className="light-24">
            {t(
              "This platform makes it possible to link the various medical devices (ultrasound scanners, measurement tools) in the vascular medical field to improve patient monitoring"
            )}
            .
          </p>
        </div>
      </div>
      <p className="text-center">
        <Link to="/contact/" className="btn-outline regular-20">
          {t("Read more +")} <ArrowRight height="15" className="ms-1" />
        </Link>
      </p>
    </section>
  )
}

export default ServicesFourthSection
