import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import "assets/sass/call_to_action_2.scss"
import ArrowRight from "images/arrow-right.inline.svg"

const ServicesFifthSection = () => {
  const { t } = useTranslation()

  return (
    <section className="call-to-action-2 section-padding">
      <div className="container">
        <p className="bold-60 call-to-action-2-title mb-4">
          {t("Are you an industrialist and interested?")}
        </p>
        <p className="light-24 mb-100 call-to-action-2-text">
          <span className="bold">
            {t("With your agreement and that of the patient")}
          </span>
          {", "}
          {t("we work on this data by performing advanced research")}
          {", "}
          <span className="bold">{t("respecting anonymity and the GDPR")}</span>
          {", "}
          {t("to improve our image analysis processing algorithms")}.
        </p>
        <p className="text-center">
          <Link to="/contact/" className="btn-primary regular-20">
            {t("Contact us")} <ArrowRight height="15" className="ms-1" />
          </Link>
        </p>
      </div>
    </section>
  )
}

export default ServicesFifthSection
