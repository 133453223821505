import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import ArrowRight from "images/arrow-right.inline.svg"

const ServicesThirdSection = () => {
  const { t } = useTranslation()

  return (
    <section className="container services-third-section section-padding">
      <h2 className="bold-40 text-center mb-100">{t("AVASCLAB API")}</h2>
      <div className="row lh-2 mb-100">
        <div className="col">
          <h3 className="bold-30 mb-2">
            {t("Integrate our solutions easily and securely:")}
          </h3>
          <p className="light-24 mb-2">
            {t("The programming interface (API) of UVASC LAB")}{" "}
            <span className="bold">
              {t("allows the integration of our solutions with yours")}
            </span>
            .
          </p>
          <p className="light-24">
            {t(
              "The data is sent to our secure servers, based in France, where it is processed and the results are immediately returned to your software solution"
            )}
            .
          </p>
        </div>
        <div className="col">
          <h3 className="bold-30 mb-2">
            {t("Benefit from the most innovative and powerful solutions:")}
          </h3>
          <p className="light-24 mb-2">{t("We ensure :")}</p>
          <ul className="content-list light-24">
            <li>{t("technological and scientific monitoring")},</li>
            <li>{t("updating our algorithms")},</li>
            <li>{t("the constant increase in the performance of our AI")}.</li>
          </ul>
        </div>
      </div>
      <p className="text-center">
        <Link to="/contact/" className="btn-outline regular-20">
          {t("Read more +")} <ArrowRight height="15" className="ms-1" />
        </Link>
      </p>
    </section>
  )
}

export default ServicesThirdSection
